import RadioButton from "./RadioButton";

type QuestionProps = {
  index: number
  entry: [string, string],
  alert: boolean,
  setAlert: (value: boolean) => void,
  selection: Selection,
  setSelection: (value: Selection) => void,
}

export enum Selection {
    Real = 'Real',
    Fake = 'Fake',
    None = 'None',
}

const Question = ({index, entry, alert, setAlert, selection, setSelection}: QuestionProps) => {

  const setValue = (value: Selection) => {
    setSelection(value)
    setAlert(false)
  }

  return (
      <div style={styles.question}>
        <div style={{...styles.container,
          border: alert ? '1px solid #dd3025' : '1px solid #e0e0e0'
        }}>
          <div style={styles.titleContainer}>
            <text style={styles.text}>
              {index + 1}. Real or fake?
            </text>
            <text style={styles.point}>
              1 point
            </text>
          </div>
          <audio controls style={styles.audio}>
            <source src={entry[1]}/>
            Your browser does not support the audio element.
          </audio>
          <div className="form-group">
            <RadioButton entry={entry} selection={selection} setSelection={setValue} type={Selection.Real}/>
            <RadioButton entry={entry} selection={selection} setSelection={setValue} type={Selection.Fake}/>
          </div>
        </div>
      </div>
  );
};

const styles = {
  text: {
    marginBottom: 8,
  },
  audio: {
    width: '100%',
    borderRadius: 20,
    marginBottom: 8
  },
  question: {
    backgroundColor: '#ffffff',
    maxWidth: 640,
    width: '100vw',
    borderRadius: 8,
    marginTop: 12,
  },
  container: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
    flexDirection: 'column' as "column",
    padding: 32,
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  point: {
    color: '#8c7d78',
    fontSize: 12,
    fontFamily: "Roboto, sans-serif",
  },
}

export default Question;
