import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useAuth} from "../components/AuthProvider";
import {useConfig} from "../components/ConfigProvider";

const Magic = () => {
  const { proxyUrl, proxyPort, config} = useConfig();

  const [params] = useSearchParams()
  const token = params.get('token') || '0'
  const [isValid, setIsValid] = useState(null as boolean | null);
  const navigate = useNavigate();

  const { login } = useAuth();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        let response;
        try {
          response = await axios.post(`${proxyUrl}:${proxyPort}/auth?token=${token}`);
        } catch (error) {
          response = await axios.post(`http://localhost:${proxyPort}/auth?token=${token}`);
        }
        if (response.status === 200) {
          login();
          navigate('/config');
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } catch (err) {
        setIsValid(false);
      }
    };
    verifyToken();
  }, [token, login, config]);
  return (
    <div>
      {isValid === null
        ? <text>Verifying token...</text>
        : <text>Invalid token</text>
      }
    </div>
  );
};

export default Magic;