import React from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../components/AuthProvider";
import Config from "../components/Config";

const ConfigPage = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Config /> : <Navigate to={'/auth'} />;
};

export default ConfigPage;