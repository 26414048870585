import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

type UploadProps = {
  handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  saveFile: () => void
  uploading: boolean
  multiple?: boolean
}

const Upload = ({handleUpload, saveFile, uploading, multiple = false}: UploadProps) => {
  return (
    <div>
      <input multiple={multiple}
        type={"file"}
        style={{marginBottom: 8}}
        onChange={handleUpload}
      />
      <div>
        <button onClick={saveFile} style={styles.button}>
          Upload
        </button>
        {uploading && <div style={{alignContent: 'center'}}>
            <text>Uploading...</text>
            <ClipLoader size={16} color={"#673ab7"} loading={uploading}/>
        </div>}
      </div>
    </div>
  );
};

const styles = {
  button: {
    padding: '10px 20px',
    backgroundColor: '#673ab7',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
}

export default Upload;