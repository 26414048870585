import React, {useEffect, useState} from 'react';
import Title from "./Title";
import Papa from "papaparse";
import axios from "axios";
import ConfigSection from "./ConfigSection";
import Upload from "./Upload";
import {useConfig} from "./ConfigProvider";

const Config = () => {
  const {proxyUrl, proxyPort, config, reload} = useConfig()

  const sections = config.sections
  const [csvData, setCsvData] = useState([] as [string, string][][])
  const [upload, setUpload] = useState([] as File[])
  const [uploading, setUploading] = useState(false)
  const [allFiles, setAllFiles] = useState([] as string[])

  const fetchCsvData = async (section: number) => {
    let csvText;
    const params = {params: {path: `${config.csvFolder}/section_${section}.csv`}}
    try {
      csvText = await axios.get(`${proxyUrl}:${proxyPort}/serve-text`, params)
    } catch (error) {
      csvText = await axios.get(`http://localhost:${proxyPort}/serve-text`, params)
    }
    return (Papa.parse(csvText.data).data as [string, string][]).filter((row) => row[0] !== '')
  }

  const fetchAllCsvData = async () => {
    return await Promise.allSettled(sections.map(
      (_, section) => fetchCsvData(section)
    )).then((results) => results.map(
      (result) => result.status === 'fulfilled' ? result.value : [])
    )
  }

  const fetchAllFiles = async () => {
    let response;
    try {
      response = await fetch(`${proxyUrl}:${proxyPort}/files?path=${config.dataFolder}`)
    } catch (error) {
      response = await fetch(`http://localhost:${proxyPort}/files?path=${config.dataFolder}`)
    }
    return await response.json()
  }
  useEffect(() => {
    fetchAllCsvData().then((data) => setCsvData(data))
    fetchAllFiles().then((data) => setAllFiles(data.files))
  }, []);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpload(Array.from(event.target.files ? event.target.files : []))
  }

  const saveFile = async () => {
    if (!upload) {
      alert('Please select a file')
      return
    }
    const formData = new FormData();
    upload.forEach(file => {
      formData.append('files', file)
    })

    setUploading(true)
    try {
      const headers = {headers: {'Content-Type': 'multipart/form-data'}}
      try {
        await axios.post(`${proxyUrl}:${proxyPort}/upload?path=${config.dataFolder}`, formData, headers)
      } catch (error) {
        await axios.post(`http://localhost:${proxyPort}/upload?path=${config.dataFolder}`, formData, headers)
      }
      alert('Files uploaded successfully')
    } catch (error) {
      alert('Error uploading file: ' + error)
    }
    setUploading(false)
    await fetchAllFiles().then((data) => setAllFiles(data.files))
  }

  const addSection = async () => {
    const newSections = [...sections, {
      formId: 'ID from the URL on the edit page',
      viewId: 'ID from the URL on the viewform page',
      title: 'Deepfake Challenge',
      subtitle: 'Can you spot the fakes?',
      description: 'Listen to the audio and select if it\'s real or fake',
    }]
    const newConfig = {...config, sections: newSections}
    try {
      await axios.post(`${proxyUrl}:${proxyPort}/config`, newConfig)
    } catch (error) {
      await axios.post(`http://localhost:${proxyPort}/config`, newConfig)
    }
    reload()
  }

  return (
    <div style={styles.bg}>
      <div style={styles.container}>
        <Title
          title={"Config"}
          subTitle={"Upload files and set questions"}
          description={"If you want to use a link to a file, you don't need to upload it here." +
            " Just paste the link in the CSV file."}
        />
        <div style={styles.section}>
          <div style={styles.sectionContainer}>
            <text>
              Upload audio files.
            </text>
            <Upload handleUpload={handleUpload} saveFile={saveFile} uploading={uploading} multiple={true}/>
            <text>
              Available files: (scroll to view more)
            </text>
            <div style={styles.scroll}>
              {allFiles.map((file, index) =>
                <text key={index} style={{fontSize: 13}}>
                  • {file}
                </text>
              )}
            </div>
          </div>
        </div>
        {sections.map((_, index) =>
          <ConfigSection
            key={index}
            index={index}
            csvData={csvData[index]}
            refreshCsvData={() => {
              fetchAllCsvData().then((data) => setCsvData(data))
            }}
          />
        )}
        <button
          style={{...styles.button, marginBottom: 12}}
          onClick={addSection}
        >
          <text style={styles.save}>
            New section
          </text>
        </button>
      </div>
    </div>
  );
};

const styles = {
  bg: {
    backgroundColor: '#f0ebf8',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as "column",
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#673ab7',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  save: {
    fontSize: '16px',
    fontFamily: "Roboto, sans-serif",
  },
  section: {
    backgroundColor: '#ffffff',
    maxWidth: 640,
    width: '100vw',
    borderRadius: 8,
    marginTop: 12,
    marginBottom: 12,
  },
  sectionContainer: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
    flexDirection: 'column' as "column",
    padding: 32,
  },
  scroll: {
    width: '100%',
    maxHeight: 240,
    overflowY: 'scroll' as "scroll",
    display: 'flex',
    flexDirection: 'column' as "column",
  }
}

export default Config;