import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Form from './pages/form';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import ConfigPage from "./pages/configPage";
import Auth from "./pages/auth";
import Magic from "./pages/magic";
import AuthProvider from "./components/AuthProvider";
import ConfigProvider from "./components/ConfigProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider>
        <BrowserRouter>
          <Routes>
              <Route index path={'form'} element={<Form />} />
              <Route path={'config'} element={<ConfigPage />} />
              <Route path={'auth'} element={<Auth />} />
              <Route path={'magic'} element={<Magic />} />
              {/*<Route path={'results'} element={<Results />} />*/}
              <Route path={'*'} element={<Navigate to={'/form?section=0'}/>} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
