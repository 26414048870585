import React, {createContext, useEffect, useState} from 'react';
import {Config} from "../types/config";
import axios from "axios";

type ConfigProviderProps = {
  children: React.ReactNode;
};

// These default values will never be used, as the AuthProvider will always be used
const ConfigContext = createContext({
  proxyUrl: "none" as string,
  proxyPort: 0 as number,
  config: {} as Config,
  reload: () => {},
});

const ConfigProvider = ({children}: ConfigProviderProps) => {
  const [config, setConfig] = useState({} as Config);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    reload()
  }, []);

  const reload = async () => {
    try {
      const response = await axios.get("https://dftbe.oxfordwaveresearch.com/config");
      setConfig(response.data);
      setLoaded(true);
    } catch (err) {
      console.error(err);
    }
  }

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const proxyUrl = "https://dftbe.oxfordwaveresearch.com"
  const proxyPort = 443

  return (
    // The ConfigContext.Provider component will provide the authentication state and functions to all children
    <ConfigContext.Provider value={{ proxyUrl, proxyPort, config, reload }}>
      {children}
    </ConfigContext.Provider>
  );
};

// The useAuth hook can be used to access the authentication state and functions
export const useConfig = () => React.useContext(ConfigContext);

export default ConfigProvider;