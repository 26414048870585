import React, {useState} from 'react';
import Upload from "./Upload";
import axios from "axios";
import Papa from "papaparse";
import {useConfig} from "./ConfigProvider";

type ConfigSectionProps = {
  csvData?: [string, string][],
  index: number, // section number
  refreshCsvData: () => void,
}

type selections = 'formId' | 'viewId' | 'title' | 'subtitle' | 'description'

const ConfigSection = ({csvData = [], index, refreshCsvData}: ConfigSectionProps) => {
  const { proxyUrl, proxyPort, config, reload} = useConfig()

  const [upload, setUpload] = useState(null as File | null)
  const [uploading, setUploading] = useState(false)

  const [field, setField] = useState('')
  const [selected, setSelected] = useState('formId' as selections)

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpload(event.target.files ? event.target.files[0] : null)
  }

  const saveFile = async () => {
    if (!upload) {
      alert('Please select a file')
      return
    }
    if (uploading) {
      alert('Please wait for the current upload to finish')
      return
    }
    setUploading(true)

    const newFile = new File([upload], `section_${index}.csv`, { type: upload.type });
    const formData = new FormData()
    formData.append('files', newFile)

    const answers = (Papa.parse(await upload.text()).data as [string, string][])
      .map((row) => row[1])

    try {
      const queryConfig = {
        headers: {'Content-Type': 'multipart/form-data'},
        params: {
          path: config.csvFolder,
          formId: config.sections[index].formId,
          answers: answers
        }
      }
      try {
        await axios.post(`${proxyUrl}:${proxyPort}/upload`, formData, queryConfig)
      } catch (error) {
        await axios.post(`http://localhost:${proxyPort}/upload`, formData, queryConfig)
      }
      alert('Files uploaded successfully')
      refreshCsvData()
    } catch (error) {
      alert('Error uploading file: ' + error)
    }
    setUploading(false)
  }

  const updateSection = async (type: selections) => {
    const newSections = [...config.sections]
    switch (type) {
      case 'formId':
        newSections[index].formId = field
        break
      case 'viewId':
        newSections[index].viewId = field
        break
      case 'title':
        newSections[index].title = field
        break
      case 'subtitle':
        newSections[index].subtitle = field
        break
      case 'description':
        newSections[index].description = field
    }
    const newConfig = {...config, sections: newSections}
    try {
      await axios.post(`http://localhost:${proxyPort}/config`, newConfig)
    } catch (error) {
      await axios.post(`${proxyUrl}:${proxyPort}/config`, newConfig)
    }
    reload()
  }

  const [confirmDelete, setConfirmDelete] = useState(false)
  const deleteSection = async () => {
    if (confirmDelete) {
      const newSections = [...config.sections]
      newSections.splice(index, 1)
      const newConfig = {...config, sections: newSections}
      try {
        await axios.post(`${proxyUrl}:${proxyPort}/config`, newConfig)
        await axios.delete(`${proxyUrl}:${proxyPort}/delete-section?id=${index}`)
      } catch (error) {
        await axios.post(`http://localhost:${proxyPort}/config`, newConfig)
        await axios.delete(`http://localhost:${proxyPort}/delete-section?id=${index}`)
      }
      reload()
      refreshCsvData()
      alert('Section Deleted')
    }
    setConfirmDelete(!confirmDelete)
  }
  
  return (
    <div style={styles.section}>
      <div style={styles.sectionContainer}>
        <div style={styles.sectionHeader}>
          <text>
            Section {index + 1}: {csvData.length} questions
          </text>
          <button onClick={deleteSection}>
            {confirmDelete ? "Click again to delete" : "Delete section"}
          </button>
        </div>
        <div style={styles.divider}/>
        <text>
          {config.sections[index].title}
        </text>
        <text>
          {config.sections[index].subtitle}
        </text>
        <text>
          {config.sections[index].description}
        </text>
        <div style={{width: "100%"}}>
          <text>
            Form ID:
            <a href={`https://docs.google.com/forms/d/${config.sections[index].formId}/edit`} style={styles.link}>
              {config.sections[index].formId}
            </a>
          </text>
        </div>
        <div style={{width: "100%"}}>
          <text>
            View ID:
            <a href={`https://docs.google.com/forms/d/e/${config.sections[index].formId}/viewform`} style={styles.link}>
              {config.sections[index].viewId}
            </a>
          </text>
        </div>
        <div style={{width: "100%", display: 'flex', marginBottom: 8}}>
          <input
            type="text"
            style={{flex: 1}}
            value={field}
            onChange={(e) => setField(e.target.value)}
          />
          <button onClick={() => updateSection(selected)} style={{width: 100}}>
            Set {selected}
          </button>
          <select
            value={selected}
            onChange={(e) => setSelected(e.target.value as selections)}>
            <option value={"formId"}>Form ID</option>
            <option value={"viewId"}>View ID</option>
            <option value={"title"}>Title</option>
            <option value={"subtitle"}>Subtitle</option>
            <option value={"description"}>Description</option>
          </select>
        </div>
        <text>
          Update CSV
        </text>
        <Upload handleUpload={handleUpload} saveFile={saveFile} uploading={uploading} multiple={false}/>
        <div style={styles.divider}/>
        Questions: (scroll to view more)
        <div style={styles.scroll}>
          {csvData.map((row, idx) =>
            <div key={idx} style={styles.question}>
              {idx !== 0 && <div style={styles.divider}/>}
              <text style={{fontSize: 13}}>
                {row[0]}
              </text>
              <br/>
              <text>
                {row[1]}
              </text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    backgroundColor: '#ffffff',
    maxWidth: 640,
    width: '100vw',
    borderRadius: 8,
    marginBottom: 12,
  },
  sectionContainer: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
    flexDirection: 'column' as "column",
    padding: 32,
  },
  sectionHeader: {
    width: "100%",
    justifyContent: 'space-between',
    display: 'flex',
  },
  question: {
    display: 'flex',
    flexDirection: 'column' as "column",
    width: '100%',
  },
  divider: {
    borderTop: '1px solid #e0e0e0',
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
  },
  scroll: {
    width: '100%',
    maxHeight: 240,
    overflowY: 'scroll' as "scroll",
    display: 'flex',
    flexDirection: 'column' as "column",
  },
  link: {
    fontSize: 14,
    marginLeft: 4,
  }
}

export default ConfigSection;