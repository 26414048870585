import React, {createContext, useState} from 'react';

type AuthProviderProps = {
  children: React.ReactNode;
};

// These default values will never be used, as the AuthProvider will always be used
const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {}
});

// The AuthProvider component will wrap the entire application
const AuthProvider = ({children}: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check if the user was authenticated before
    const savedAuthState = localStorage.getItem('isAuthenticated');
    return savedAuthState === 'true';
  });
  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false');
  };

  return (
    // The AuthContext.Provider component will provide the authentication state and functions to all children
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// The useAuth hook can be used to access the authentication state and functions
export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;