import React from 'react';
import Title from "../components/Title";
import axios from "axios";
import {useConfig} from "../components/ConfigProvider";

const Auth = () => {
  const {proxyUrl, proxyPort, config} = useConfig();

  const [email, setEmail] = React.useState('');

  const handleSubmit = async () => {
    if (!config.allowedEmails.includes(email)) {
      alert('Email not authorised')
      return
    }
    try {
      try {
        await axios.get(`${proxyUrl}:${proxyPort}/auth`, {params: {email: email}});
      } catch (error) {
        await axios.get(`http://localhost:${proxyPort}/auth`, {params: {email: email}});
      }
      alert('Link sent to ' + email)
    } catch (error) {
      alert('Error sending link')
    }
  }

  return (
    <div style={styles.bg}>
      <div style={styles.container}>
        <Title
          title={"Authenticate"}
          subTitle={"You must be authenticated to access the config page."}
          description={"Enter your email to have a link sent to you."}
        />
        <div style={styles.section}>
          <div style={styles.sectionContainer}>
            <text>
              Email:
            </text>
            <input
              type={'email'}
              value={email}
              style={styles.input}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button
              style={styles.button}
              onClick={handleSubmit}
            >
              Send link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  bg: {
    backgroundColor: '#f0ebf8',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as "column",
  },
  section: {
    backgroundColor: '#ffffff',
    maxWidth: 640,
    width: '100vw',
    borderRadius: 8,
    marginTop: 12,
    marginBottom: 12,
  },
  sectionContainer: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
    flexDirection: 'column' as "column",
    padding: 32,
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#673ab7',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: "Roboto, sans-serif",
  },
  input: {
    width: '100%',
    padding: 8,
    marginTop: 8,
    marginBottom: 8
  }
}

export default Auth;