import React, {useEffect, useState} from 'react';
import Question, {Selection} from "../components/Question";
import Title from "../components/Title";
import {useSearchParams} from "react-router-dom";
import Papa from 'papaparse';
import axios from "axios";
import NameEntry from "../components/NameEntry";
import {useConfig} from "../components/ConfigProvider";

const Form = () => {
  const {proxyUrl, proxyPort, config} = useConfig()

  const [params] = useSearchParams()
  const section = Number(params.get('section')) || 0

  const sectionConfig = config.sections[section]
    ? config.sections[section] : {formId: "", viewId: "", title: "", subtitle: "", description: ""}

  const link = `https://docs.google.com/forms/d/${sectionConfig.formId}`
  const submitLink = `${link}/formResponse`

  // Extract entry numbers from the form and add the corresponding file links
  const extractEntries = async () => {
    if (section >= config.sections.length) {
      return ['', []] as [string, [string, string][]]
    }

    const url = `https://docs.google.com/forms/d/e/${sectionConfig.viewId}/viewform`
    // Fetch the form page (HTML) and extract entry numbers
    // We need to use a proxy because of CORS
    let response;
    let local: boolean;
    try {
      response = await fetch(`${proxyUrl}:${proxyPort}/proxy?url=${url}`)
      local = false
    } catch (error) {
      response = await fetch(`http://localhost:${proxyPort}/proxy?url=${url}`)
      local = true
    }
    const text = await response.text()
    const doc = new DOMParser().parseFromString(text, 'text/html')
    const dataElements = doc.querySelectorAll('div[data-params]')
    const allIds = Array.from(dataElements)
      .map((element) => (
        (element.getAttribute('data-params') || '')
          .match(/\b\d+\b/g) || [])[2]
      )
    const [nameId, ...entryElements] = allIds

    // Fetch the CSV file for the section and extract file links
    const localUrl = local ? "http://localhost" : proxyUrl
    const csvText = await axios.get(`${localUrl}:${proxyPort}/serve-text`, {
      params: { path: `${config.csvFolder}/section_${section}.csv` }
    })
    const csvData = Papa.parse(csvText.data).data as [string, number][]
    const files = csvData.map((row: [string, number]) => row[0])

    if (entryElements.length > files.length) {
      throw new Error('Number of entries is greater than number of files.\n' +
          'Please make sure the Google Form and config.json are in sync.\n' +
          'Each form entry should have a corresponding file link.')
    }

    const entries: [string, string][] = entryElements.map((id, index) => {
      if (!id) {
        throw new Error('Entry name not found')
      }
      // Get links for local files, otherwise use the link as is
      const fileLink = files[index].startsWith('http')
          ? files[index]
          : `${localUrl}:${proxyPort}/serve-file?path=${config.dataFolder}/${files[index]}`
      return [`entry.${id}`, fileLink]
    })
    return [`entry.${nameId}`, entries] as [string, [string, string][]]
  }

  const [nameEntry, setNameEntry] = useState('')
  const [entries, setEntries] = useState([] as [string, string][])
  useEffect(() => {
    extractEntries()
        .then(([nameEntry, entries]) => {
          setNameEntry(nameEntry)
          setEntries(entries)
        })
  }, []);

  useEffect(() => {
    setAlerts(entries.map(() => false))
    setSelections(entries.map(() => Selection.None))
  }, [entries]);

  const [alerts, setAlerts] = useState([] as boolean[])
  const [selections, setSelections] = useState([] as Selection[])

  if (section >= config.sections.length) {
    return (
        <div style={styles.bg}>
          <div style={styles.form}>
            <Title
                title={"Error"}
                subTitle={"Section not found"}
                description={"Please check the URL"}
            />
          </div>
        </div>
    )
  }

  return (
    <div className="App" style={styles.bg}>
      <form
          action={submitLink}
          style={styles.form}
      >
        <Title
          title={sectionConfig.title}
          secondaryTitle={`Section ${section + 1}`}
          subTitle={sectionConfig.subtitle}
          description={sectionConfig.description}
          alert={alerts.some((value) => value)}
        />
        <div>
          <NameEntry entry={nameEntry}/>
          {entries.map((entry, index) =>
            <Question index={index} entry={entry} alert={alerts[index]} setAlert={
              (value) => {
                const newAlerts = [...alerts]
                newAlerts[index] = value
                setAlerts(newAlerts)
              }} selection={selections[index]} setSelection={
              (value) => {
                const newSelections = [...selections]
                newSelections[index] = value
                setSelections(newSelections)
              }} key={index}/>
          )}
        </div>
        <div style={styles.footer}>
          {selections.some((value) => value === Selection.None)
            ? <button type="button" style={styles.button}
                      onClick={() => {
                        setAlerts(selections.map((value) => value === Selection.None))
                        window.scrollTo(0, 0)
                      }}>
              <text style={styles.submit}>
                Submit
              </text>
            </button>
            : <button type="submit" style={styles.button}>
                <text style={styles.submit}>
                  Submit
                </text>
              </button>}
          <text
              style={styles.clearForm}
              onClick={() => {
                setSelections(entries.map(() => Selection.None))
                setAlerts(entries.map(() => false))
                window.scrollTo(0, 0)
              }
          }>
            Clear Form
          </text>
        </div>
      </form>
    </div>
  );
}

const styles = {
  bg: {
    backgroundColor: '#f0ebf8',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#673ab7',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: 16,
  },
  submit: {
    fontSize: '16px',
    fontFamily: "Roboto, sans-serif",
  },
  form: {
    display: 'flex',
    flexDirection: 'column' as "column",
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  clearForm: {
    color: '#673ab7',
    fontSize: 14,
    fontWeight: '500',
    fontFamily: "Open Sans, sans-serif",
    marginRight: 8,
    cursor: 'pointer',
    marginTop: 8,
  },
}

export default Form;
