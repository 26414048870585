import React, {useState} from 'react';
import {Selection} from "./Question";

type RadioButtonProps = {
  entry: [string, string],
  type: Selection,
  selection: Selection,
  setSelection: (type: Selection) => void,
}

const RadioButton = ({entry, type, selection, setSelection}: RadioButtonProps) => {
  const [hover, setHover] = useState(false)
  return (
    <div className="radio" style={{marginBottom: 12}}>
      <label
        htmlFor={`${entry[0]}_${type}`}
        onClick={() => setSelection(type)}
      >
        {hover && <span style={styles.hoverBg}/>}
        <span
          style={{
            ...styles.hoverButton,
            backgroundColor: selection === type ? '#673ab7' : '#ffffff'
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
        <input
          type="radio"
          id={`${entry[0]}_${type}`}
          name={entry[0]}
          value={type.toString()}
          required={true}
          style={styles.button}
        />
        {type.toString()}
      </label>
    </div>
  );
};

const styles = {
  hoverBg: {
    marginLeft: -11,
    marginTop: -8,
    width: 41,
    height: 41,
    borderRadius: 20,
    backgroundColor: '#f9f7fc',
    position: 'absolute' as "absolute",
    zIndex: 0,
  },
  hoverButton: {
    position: 'absolute' as "absolute",
    height: 16,
    width: 16,
    borderRadius: 10,
    marginLeft: 0,
    marginTop: 2,
    zIndex: 1,
    border: '2px solid #ddd',
    cursor: 'pointer',
  },
  button: {
    marginRight: 16,
    zIndex: 2,
  },
}

export default RadioButton;