import React from 'react';

type NameEntryProps = {
  entry: string;
}

const NameEntry = ({entry}: NameEntryProps) => {
  return (
    <div style={styles.section}>
      <div style={styles.sectionContainer}>
        <text>
          Name (Optional)
        </text>
        <input
          name={entry}
          style={styles.input}
        />
      </div>
    </div>
  );
};

const styles = {
  section: {
    backgroundColor: '#ffffff',
    maxWidth: 640,
    width: '100vw',
    borderRadius: 8,
    border: '1px solid #e0e0e0',
    marginTop: 12,
    marginBottom: 12,
  },
  sectionContainer: {
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column' as "column",
    padding: 32,
  },
  input: {
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
  }
}

export default NameEntry;