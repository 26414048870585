import React from 'react';

type TitleProps = {
  title: string
  secondaryTitle?: string
  subTitle: string
  description: string
  alert?: boolean
}

const Title = ({title, secondaryTitle = "", subTitle, description, alert = false}: TitleProps) => {



  return (
    <div style={styles.title}>
      <div style={styles.bar}/>
      <div style={styles.titleContainer}>
        <text style={styles.titleText}>
          {title}
        </text>
        {secondaryTitle && <text style={styles.secondaryTitle}>
          {secondaryTitle}
        </text>}
      </div>
      <text style={styles.subTitleText}>
        {subTitle}
      </text>
      <div style={styles.divider}/>
      <text style={styles.subTitleText}>
        {description}
      </text>
      {alert && <>
          <div style={styles.divider}/>
          <text style={{color: '#dd3025', marginLeft: 25}}>
              ⓘ Please answer all questions
          </text>
      </>}
    </div>
  );
};

const styles = {
  title: {
    backgroundColor: '#ffffff',
    maxWidth: 640,
    width: '100vw',
    borderRadius: 8,
    marginTop: 11,
    border: '1px solid #e0e0e0',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column' as "column",
    paddingBottom: 12,
  },
  titleText: {
    fontSize: 32,
    fontWeight: '400',
    fontFamily: "Roboto, sans-serif",
  },
  subTitleText: {
    fontSize: 14,
    fontWeight: '400',
    marginLeft: 28,
    fontFamily: "Open Sans, sans-serif",
  },
  divider: {
    borderTop: '1px solid #e0e0e0',
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
  },
  bar: {
    backgroundColor: '#673ab7',
    height: 10,
    width: '100%',
    borderRadius: '8px 8px 0 0',
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: 588,
    justifyContent: 'space-between',
    marginTop: 16,
    marginLeft: 25,
    marginBottom: 8,
  },
  secondaryTitle: {
    alignSelf: 'flex-end',
    fontSize: 24,
    fontWeight: '400',
    color: '#4a4a4a',
    fontFamily: "Roboto, sans-serif",
  },
}


export default Title;